import { Transition } from '@headlessui/react'
import { ArrowPathIcon } from '@heroicons/react/20/solid'
import useSettingsStore, { PlatformMode } from '@store/settingsStore'
import { IS_VERBOSE } from 'config/development'
import useBreakpoints from 'hooks/useBreakpoints'
import dynamic from 'next/dynamic'
import { Fragment, ReactNode, useEffect, useState } from 'react'
import { nftThemeMeta } from 'utils/theme'
import { SECONDS } from '../utils/constants'
import useInterval from './shared/useInterval'
import { PPSupplyMono } from 'utils/fonts'

const GlobalSearchModal = dynamic(
  () => import('./TopBar/GlobalSearch/GlobalSearchModal'),
)

const LiteLayout = dynamic(() => import('./LiteLayout'))
const ProLayout = dynamic(() => import('./ProLayout'))

export const sideBarAnimationDuration = 300

const Layout = ({ children }: { children: ReactNode }) => {
  const themeData = nftThemeMeta.default
  const { below } = useBreakpoints()
  const { isPro, updateProp } = useSettingsStore()

  const [mounted, setMounted] = useState(false)

  useEffect(() => setMounted(true), [])

  useEffect(() => {
    if (below.xl && isPro()) {
      updateProp('platformMode', PlatformMode.Lite)
    }
  }, [below.xl])

  if (!mounted) {
    return null
  }

  return (
    <main className={`${PPSupplyMono.variable} font-sans relative`}>
      {!isPro() ? (
        <LiteLayout>{children}</LiteLayout>
      ) : (
        <ProLayout theme={themeData}>{children}</ProLayout>
      )}

      <DeployRefreshManager />
      <GlobalSearchModal />
    </main>
  )
}

function DeployRefreshManager(): JSX.Element | null {
  const [newBuildAvailable, setNewBuildAvailable] = useState(false)

  useInterval(async () => {
    try {
      const response = await fetch('/api/build-id')
      const { buildId } = await response.json()

      if (buildId && process.env.BUILD_ID && buildId !== process.env.BUILD_ID) {
        // There's a new version deployed that we need to load
        setNewBuildAvailable(true)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      IS_VERBOSE && console.log(e)
    }
  }, 300 * SECONDS)

  return (
    <Transition
      appear={true}
      show={newBuildAvailable}
      as={Fragment}
      enter="transition ease-in duration-300"
      enterFrom="translate-y-0"
      enterTo="-translate-y-[130px] md:-translate-y-20"
      leave="transition ease-out"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <button
        className="fixed -bottom-[46px] left-1/2 z-50 flex -translate-x-1/2 items-center rounded-full border border-th-bkg-4 bg-th-bkg-3 px-4 py-3 shadow-md focus:outline-none md:hover:bg-th-bkg-4 md:hover:shadow-none"
        onClick={() => window.location.reload()}
      >
        <p className="mr-2 whitespace-nowrap text-th-fgd-1">{'New Version'}</p>
        <ArrowPathIcon className="h-5 w-5" />
      </button>
    </Transition>
  )
}
export default Layout
